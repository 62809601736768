import { render, staticRenderFns } from "./previewTransRecordDialog.vue?vue&type=template&id=53ea1764&scoped=true&"
import script from "./previewTransRecordDialog.vue?vue&type=script&lang=js&"
export * from "./previewTransRecordDialog.vue?vue&type=script&lang=js&"
import style0 from "./previewTransRecordDialog.vue?vue&type=style&index=0&id=53ea1764&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ea1764",
  null
  
)

export default component.exports